import { PipesModule } from '../../../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ThemeModule } from '../../../../@theme/theme.module';
// components
import {
    NbActionsModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbRadioModule,
    NbSelectModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbTooltipModule,
    NbUserModule
} from '@nebular/theme';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { ProductTableComponent } from './product-table/product-table.component';
import { ProductComponent } from './product/product.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AgmCoreModule } from '@agm/core';
import { ComponentsModule } from '../../../../components/components.module';
import { ProductInstanceComponent } from './product/product-instance/product-instance.component';
import { ProductOptionComponent } from './product/product-option/product-option.component';
import { EversysProductGeneratorDialog } from './eversys-product-generator/eversys-product-generator-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductImageEditorComponent } from './product/product-image-editor/product-image-editor.component';

// components

const NB_MODULES = [
    NbActionsModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbTabsetModule,
    NbUserModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbSpinnerModule,
    NbDatepickerModule,
    NbInputModule,
    NbTooltipModule,
    NbSelectModule,
    DragDropModule,
];

@NgModule({
    imports: [
        ProductsRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBdhZ7MrgJuOLKyxROWBfV7pU8AZixh6Hc',
            libraries: ['places']
        }),
        ...NB_MODULES,
        ThemeModule,
        Ng2SmartTableModule,
        ComponentsModule,
        FormsModule,
        PipesModule,
        NbCheckboxModule,
    ],
    declarations: [
        ProductsComponent,
        ProductTableComponent,
        ProductComponent,
        ProductInstanceComponent,
        ProductOptionComponent,
        EversysProductGeneratorDialog,
        ProductImageEditorComponent,
    ],
    entryComponents: [],
    providers: []
})

export class ProductsModule {
}
